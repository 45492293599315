import { useState, useEffect, useRef, useCallback } from 'react';
import axios from "axios";

export const useUserApp = () => {
    const [user, setUser] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [activeTab, setActiveTab] = useState(() => {
        // Восстанавливаем активный таб из localStorage
        return parseInt(localStorage.getItem('activeTab'), 10) || 0;
    });
    const wsRef = useRef(null);

    // Подключение WebSocket
    const connectWebSocket = useCallback((currentUser) => {
        if (!wsRef.current && currentUser && reconnectAttempts < 3) {
            console.log('Opening WebSocket connection');
            const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            const wsHost = window.location.hostname === 'localhost' ? 'localhost:3030' : 'thebase-gup.ru';
            wsRef.current = new WebSocket(`${wsProtocol}://${wsHost}/ws/`);

            wsRef.current.onopen = () => {
                console.log('WebSocket connection opened');
                setReconnectAttempts(0);
            };
            wsRef.current.onerror = (error) => console.error('WebSocket error:', error);

            wsRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log('WebSocket message:', data);
                switch (data.type) {
                    case 'news':
                        break;
                    case 'notification':
                        if (data.targetGroups && data.targetGroups.includes(currentUser.group)) {
                        }
                        break;
                    default:
                        break;
                }
            };

            wsRef.current.onclose = (event) => {
                console.log('WebSocket connection closed');
                if (!event.wasClean) {
                    console.error('Connection closed with error');
                    if (navigator.onLine && reconnectAttempts < 3) {
                        setReconnectAttempts((prev) => {
                            const newAttempts = prev + 1;
                            if (newAttempts < 3) {
                                setTimeout(() => connectWebSocket(currentUser), 3000);
                            } else {
                                console.error('Максимальное количество попыток переподключения исчерпано.');
                            }
                            return newAttempts;
                        });
                    }
                }
                wsRef.current = null;
            };
        }
    }, [reconnectAttempts]);

    // Восстановление пользователя при монтировании
    useEffect(() => {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            const parsedUser = JSON.parse(savedUser);
            setUser(parsedUser);
            connectWebSocket(parsedUser);
        }
    }, [connectWebSocket]);

    useEffect(() => {
        if (user) {
            connectWebSocket(user);
        }
    }, [connectWebSocket, user]);

    // Сохранение активного таба
    const handleTabSelect = (index) => {
        setActiveTab(index);
        localStorage.setItem('activeTab', index);
    };

    // Логин
    const login = async (username, rememberMe) => {
        try {
            const response = await axios.post('/login', { username });
            if (response.data.success) {
                const loggedInUser = response.data.user;
                setUser(loggedInUser);
                setErrorMessage('');
                if (rememberMe) {
                    localStorage.setItem('user', JSON.stringify(loggedInUser));
                }
                connectWebSocket(loggedInUser);
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage('Ошибка входа');
        }
    };

    // Логаут
    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('activeTab'); // Очистка состояния таба
        setUser(null);
        if (wsRef.current) {
            console.log('Closing WebSocket connection');
            wsRef.current.close();
            wsRef.current = null;
        }
    };

    return {
        user,
        errorMessage,
        login,
        logout,
        reconnectAttempts,
        wsRef,
        activeTab,
        handleTabSelect
    };
};
