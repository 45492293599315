import '../styles/NewsPage.css'
import RichTextEditor from './RichTextEditor';
import { useState } from 'react';


const CommentAndReply = ({
    item,
    currentUserId,
    toggleReplyInput,
    reply,
    setReply,
    addReply,
    comments,
    setComments,
    addComment,
}) => {
    const [isRichActive, setIsRichActive] = useState(false);

    return (
        <div className="comments-section">
            {item.comments && item.comments.map((c, index) => (
                <div className="comment" key={index}>
                    <div className="comment-header">
                        <span className={c.userId === currentUserId ? 'user-name user-current' : 'user-name user-other'}>
                            {c.userName}
                        </span>
                        <span className="timestamp timestamp-left">
                            {new Date(c.timestamp).toLocaleString('ru-RU', {
                                day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'
                            })}
                        </span>
                    </div>
                    <p style={{ margin: 0, marginBottom: '20px' }} dangerouslySetInnerHTML={{ __html: c.comment }} />
                    <button className="reply-button" onClick={() => toggleReplyInput(c.id)}>Ответить</button>
                    {(c.replies ? c.replies : []).map((r, replyIndex) => (
                        <div className='comment-wrapper' key={replyIndex}>
                            <div className="comment-reply">
                                <span className={r.userId === currentUserId ? 'user-name user-current' : 'user-name user-other'}>
                                    {r.userName}:
                                </span>
                                <span dangerouslySetInnerHTML={{ __html: r.reply }} />
                                <span className="timestamp">
                                    {new Date(r.timestamp).toLocaleString('ru-RU', {
                                        day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'
                                    })}
                                </span>
                            </div>
                        </div>
                    ))}
                    {reply.commentId === c.id && (
                        <div>
                            <RichTextEditor
                                className="reply-input"
                                placeholder="Ваш ответ..."
                                newContent={reply.text}
                                setNewContent={(content) => setReply({ ...reply, text: content })}
                            />
                            <button className="reply-button" onClick={() => addReply(item.id, c.id)}>Отправить</button>

                        </div>
                    )}
                </div>
            ))}
            {isRichActive ? (
                <RichTextEditor
                    className="comment-input"
                    newContent={comments[item.id] || ''}
                    setNewContent={(content) => setComments({ ...comments, [item.id]: content })}
                    placeholder="Ваш комментарий..."
                />
            ) : (
                <input
                    className='comment-input'
                    placeholder='click me bitch'
                    onClick={() => setIsRichActive(true)}
                />
            )}
            {isRichActive && (
                <div>
                    <button className="comment-button" onClick={() => addComment(item.id)}>Добавить комментарий</button>
                    <button className='comment-button' onClick={() => { setIsRichActive(false); setComments('') }}>Отмена</button>
                </div>
            )}
        </div>
    )
}

export default CommentAndReply;