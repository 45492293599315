import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './styles/NewsPage.css';
import RichTextEditor from './connectables/RichTextEditor';
import CommentAndReply from './connectables/CommentAndReply';

const NewsPage = ({ user, ws }) => {
    const [news, setNews] = useState([]);
    const [comments, setComments] = useState({});
    const [reply, setReply] = useState({ commentId: null, text: '' });
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const newsPerPage = 3;
    let currentUserId = user.id;

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('/news');
                setNews(response.data);
            } catch (error) {
                console.error("Ошибка загрузки новостей:", error);
            }
        };
        fetchNews();
    }, []);

    const handleWsMessage = (event) => {
        try {
            const data = JSON.parse(event.data);

            switch (data.type) {
                case 'news':
                    setNews((prevNews) => {
                        const isDuplicate = prevNews.some(newsItem => newsItem.id === data.data.id);
                        return isDuplicate ? prevNews : [data.data, ...prevNews];
                    });
                    break;

                case 'news-delete':
                    setNews((prevNews) => prevNews.filter(news => news.id !== data.data.id));
                    break;

                case 'comment':
                    setNews((prevNews) =>
                        prevNews.map((newsItem) =>
                            newsItem.id === data.newsId
                                ? {
                                    ...newsItem,
                                    comments: [...(newsItem.comments || []), { ...data.newComment, replies: [] }]
                                }
                                : newsItem
                        )
                    );
                    break;

                case 'reply':
                    setNews((prevNews) =>
                        prevNews.map((newsItem) =>
                            newsItem.id === data.newsId
                                ? {
                                    ...newsItem,
                                    comments: newsItem.comments.map((commentItem) =>
                                        commentItem.id === data.commentId
                                            ? { ...commentItem, replies: [...(commentItem.replies || []), data.newReply] }
                                            : commentItem
                                    ),
                                }
                                : newsItem
                        )
                    );
                    break;

                default:
                    console.warn("Неизвестный тип сообщения или неполные данные:", data);
            }
        } catch (error) {
            console.error("Ошибка обработки сообщения WebSocket:", error);
        }
    };

    useEffect(() => {
        if (ws) {
            ws.addEventListener('message', handleWsMessage);
        }
        return () => {
            if (ws) {
                ws.removeEventListener('message', handleWsMessage);
            }
        };
    }, [ws]);

    const addComment = async (newsId) => {
        try {
            const response = await axios.post(`/news/${newsId}/comment`, {
                userId: currentUserId,
                userName: user.name,
                comment: comments[newsId] || ''
            });
            setNews(prevNews => prevNews.map(newsItem => {
                if (newsItem.id === newsId) {
                    if (!newsItem.comments.some(comment => comment.id === response.data.id)) {
                        return {
                            ...newsItem,
                            comments: [...(newsItem.comments || []), { ...response.data, replies: [] }]

                        };
                    }
                }
                return newsItem;

            }));
            setComments(prevComments => ({ ...prevComments, [newsId]: '' }));
        } catch (error) {
            console.error("Ошибка при добавлении комментария:", error);
        }
    };

    const addReply = async (newsId, commentId) => {
        try {
            const response = await axios.post(`/news/${newsId}/comment/${commentId}/reply`, {
                userId: currentUserId,
                userName: user.name,
                comment: reply.text
            });

            setNews(prevNews => prevNews.map(newsItem =>
                newsItem.id === newsId
                    ? {
                        ...newsItem,
                        comments: newsItem.comments.map(comment =>
                            comment.id === commentId
                                ? {
                                    ...comment,
                                    replies: comment.replies.some(reply => reply.id === response.data.id)
                                        ? comment.replies
                                        : [...comment.replies, response.data]
                                }
                                : comment
                        ),
                    }
                    : newsItem
            )
            );

            setReply({ commentId: null, text: '' });
        } catch (error) {
            console.error("Ошибка при добавлении ответа на комментарий:", error);
        }
    };


    const addNews = async () => {
        try {
            const response = await axios.post('/news', { title: newTitle, content: newContent, userId: currentUserId });
            setNews((prevNews) => {
                if (prevNews.some(newsItem => newsItem.id === response.data.id)) {
                    return prevNews;
                }
                return [response.data, ...prevNews];
            });
            setNewTitle('');
            setNewContent('');
        } catch (error) {
            console.error("Ошибка при добавлении новости:", error);
        }
    };

    const deleteNews = async (newsId) => {
        try {
            await axios.delete(`/news/${newsId}`, { data: { userId: currentUserId } });
            setNews(prevNews => prevNews.filter(news => news.id !== newsId));
        } catch (error) {
            console.error("Ошибка при удалении новости:", error);
        }
    };

    const toggleReplyInput = (commentId) => {
        if (reply.commentId === commentId) {
            setReply({ commentId: null, text: '' });
        } else {
            setReply({ commentId, text: '' });
        }
    };

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;

    const currentNews = news
        .slice()
        .sort((a, b) => b.id - a.id)
        .slice(indexOfFirstNews, indexOfLastNews);

    const totalPages = Math.ceil(news.length / newsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [isCreating, setIsCreating] = useState(false);
    const toggleCreateNews = () => {
        setIsCreating(prev => !prev);
    }

    return (
        <div className='news-container'>
            {user.id === 1 && (
                <button onClick={toggleCreateNews} style={{ marginBottom: '15px' }}>
                    {isCreating ? 'Отмена' : 'Создать новость'}
                </button>
            )}
            {isCreating && user.id === 1 && (
                <div className='news-wrapper'>
                    <h2>Добавить новость</h2>
                    <input className='news-creation'
                        type="text"
                        placeholder="Заголовок"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    /><br />
                    <RichTextEditor
                        newContent={newContent}
                        setNewContent={setNewContent}
                    /><br />
                    <button onClick={addNews}>Добавить новость</button>
                </div>
            )}
            <div className={`${user.id === 1 ? 'news-container__bottom' : ''}`}>
                {currentNews.map(item => (
                    <div className="news-item" key={item.id}>
                        <div className='news-item__title-wrapper'>
                            <h2 className="news-title">{item.title}</h2>
                            <span className="timestamp">
                                {new Date(item.timestamp).getFullYear() === new Date().getFullYear()
                                    ? new Date(item.timestamp).toLocaleString('ru-RU', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })
                                    : new Date(item.timestamp).toLocaleString('ru-RU', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })
                                }
                            </span>
                            {user.id === 1 && (
                                <button onClick={() => deleteNews(item.id)} className="delete-button">
                                    🗑️
                                </button>
                            )}
                        </div>
                        <div
                            className="news-content"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                        <CommentAndReply
                            item={item}
                            currentUserId={currentUserId}
                            toggleReplyInput={toggleReplyInput}
                            reply={reply}
                            setReply={setReply}
                            addReply={addReply}
                            comments={comments}
                            setComments={setComments}
                            addComment={addComment}
                        />
                    </div>
                ))}
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={page === currentPage ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NewsPage;
