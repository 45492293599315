import ReactQuill from 'react-quill';
import { useEffect, useRef } from 'react';
import { addEmojiButton } from '../modules/emojiPool';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ setNewContent, newContent }) => {
    const quillRef = useRef(null);

    useEffect(() => {
        addEmojiButton(quillRef);
    }, []);
    const modules = {
        toolbar: [
            [{ font: [] }, { size: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'script', 'header', 'blockquote', 'code-block',
        'list', 'bullet',
        'align', 'link', 'image', 'video',
    ];

    return (
        <div>
            <ReactQuill
                ref={quillRef}
                value={newContent}
                onChange={content => setNewContent(content)}
                theme="snow"
                modules={modules}
                formats={formats}
            />
        </div>
    );
};

export default RichTextEditor;
