import React from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Login from './Login';
import SearchPage from './SearchPage';
import TopPage from './TopPage';
import WeeklyStats from './WeeklyStats';
import AccountManagement from './AccountManagement';
import NotificationManagement from './NotificationManagement';
import Notifications from './Notifications';
import NewsPage from './NewsPage';
import './styles/App.css';
import './styles/tabpanel.css';
import './styles/style.css';
import logos from './assets/logos.svg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Workspace from './Workspace';
import { useUserApp } from './hooks/useUserApp';

function App() {
  const { user, errorMessage, login, logout, wsRef, activeTab, handleTabSelect } = useUserApp();

  return (
    <div className="App">
      <ToastContainer />
      <div className='header'>
        {user && (
          <div className="notifications-logout">
            <Notifications user={user} ws={wsRef.current} />
            <span className="user-info">Пользователь: {user.name}</span>
            <button onClick={logout}>Выйти</button>
          </div>
        )}
      </div>
      <div className='logo-container'>
        <img src={logos} alt="Logo" className='logo' />
      </div>
      <div className="menu">
        {!user ? (
          <Login onLogin={login} errorMessage={errorMessage} />
        ) : (
          <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
            <TabList>
              <Tab>Новости</Tab>
              <Tab>Поиск ЦОК</Tab>
              <Tab>Топ ЦОКи</Tab>
              <Tab>Статистика</Tab>
              {(user.id === 1 || user.id === 1022) && <Tab>Управление аккаунтами</Tab>}
              {(user.id === 1 || user.id === 1022) && <Tab>Управление уведомлениями</Tab>}
              <Tab>Задачи</Tab>
            </TabList>
            <TabPanel>
              <NewsPage user={user} ws={wsRef.current} />
            </TabPanel>
            <TabPanel>
              <SearchPage user={user} />
            </TabPanel>
            <TabPanel>
              <TopPage />
            </TabPanel>
            <TabPanel>
              <WeeklyStats user={user} />
            </TabPanel>
            {(user.id === 1 || user.id === 1022) && (
              <TabPanel>
                <AccountManagement user={user} />
              </TabPanel>
            )}
            {(user.id === 1 || user.id === 1022) && (
              <TabPanel>
                <NotificationManagement user={user} />
              </TabPanel>
            )}
            <TabPanel>
              <Workspace user={user} />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default App;
