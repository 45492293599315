import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './styles/Workspace.css';
import CreateTaskForm from './connectables/CreateTaskForm';
import TaskComments from './connectables/TaskComments';
import { fetchUsers, getUserNameById } from './modules/userService'
import RichTextEditor from './connectables/RichTextEditor';

function Workspace({ user }) {
    const [tasks, setTasks] = useState([]);
    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]); // Храним список проектов
    const [selectedProjectId, setSelectedProjectId] = useState(null); // Храним ID выбранного проекта
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [subtaskSortConfig, setSubtaskSortConfig] = useState({ key: 'title', direction: 'ascending' });
    const [subtasks, setSubtasks] = useState([]);
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [showTaskPanel, setShowTaskPanel] = useState(false);
    const [showSubtaskPanel, setShowSubtaskPanel] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTask, setEditingTask] = useState(null);
    const [editingSubtask, setEditingSubtask] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [parentTask, setParentTask] = useState(null);
    const [timers, setTimers] = useState({}); // Храним таймеры для всех задач
    const intervalRefs = useRef({}); // Храним ссылки на интервалы
    const [pinnedComment, setPinnedComment] = useState(); // Храним закрепленный текст коммента

    const activeTask = editingTask || editingSubtask;
    let currentUserId = user.id;
    const statusMapping = {
        new: "Новая задача",
        in_progress: "В работе",
        paused: "На паузе",
        deferred: "Отложена",
        closed: "Завершена",
    }
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        assigner_id: null,
        executor_id: null,
        observers: [],
        tag: '',
        parent_task_id: null,
        deadline: null,
        project_id: null,
        project_name: null,
    });

    const fetchProjects = async () => {
        try {
            const response = await axios.get('/projects');
            const fetchedProjects = response.data;

            // сортировка проектов автоматически по времени идет, сделал по id
            const sortedProjects = fetchedProjects.sort((a, b) => a.id - b.id);

            setProjects(sortedProjects);

            if (sortedProjects.length > 0) {
                // Устанавливаем первый проект как предвыбранный
                setSelectedProjectId(sortedProjects[0].id);
            }
        } catch (error) {
            console.error('Ошибка при получении проектов:', error);
        }
    };

    useEffect(() => {
        fetchProjects(); // Загружаем проекты
        fetchUsers(setUsers); // Загружаем пользователей
    }, []);
    const fetchTasks = React.useCallback(async () => {
        try {
            const url = selectedProjectId
                ? `/projects/${selectedProjectId}/tasks` // Загружаем задачи по выбранному проекту
                : '/tasks';
            const response = await axios.get(url);

            setTasks(response.data);
            const extractSubtasks = (tasks) => {
                let subtasksList = [];
                tasks.forEach((task) => {
                    if (task.subtasks && task.subtasks.length > 0) {
                        subtasksList = subtasksList.concat(task.subtasks);
                        subtasksList = subtasksList.concat(extractSubtasks(task.subtasks)); // Рекурсивно извлекаем подзадачи
                    }
                });
                return subtasksList;
            };

            const allSubtasks = extractSubtasks(response.data);

            setSubtasks(allSubtasks); // Сохраняем подзадачи в состояние subtasks
        } catch (error) {
            console.error('Ошибка при получении задач:', error);
        }
    }, [selectedProjectId]);

    useEffect(() => {
        if (selectedProjectId !== null) {
            fetchTasks(); // Загружаем задачи только если проект выбран
        }
    }, [selectedProjectId, fetchTasks]);

    const extractNumberFromTitle = (title) => {

        const eomPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5}-m\d\.\d+m?[-.]\d{2}[-.]\d{2})/g;
        const cokPattern = /(\d{1,2}(?:\.\d{1,2})?[-.]\d{1,2}[-.]\d{5})/g;

        const eomMatches = title.match(eomPattern);
        if (eomMatches) {
            return eomMatches[0];
        }

        const cokMatches = title.match(cokPattern);
        if (cokMatches) {
            return cokMatches[0];
        }

        console.log("Номер не найден ни по одному шаблону.");
        return null;
    };

    const searchCokByNumber = async (cokNumber) => {
        if (!cokNumber) return;
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('/search-cok', { params: { cok: cokNumber } });

            if (response.data && response.data.cok) {
                const result = `${response.data.cok} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult("ЦОК не найден");
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЦОК');
            setSearchResult("ЦОК не найден");
        } finally {
            setLoading(false);
        }
    };

    const searchEomByNumber = async (eomNumber, userId = 1) => {
        if (!eomNumber) return;
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get('/search-eom', { params: { eom: eomNumber, userId } });

            if (response.data && response.data.eom) {
                const result = `${response.data.eom} — ${response.data.status || "Новый"}`;
                setSearchResult(result);
            } else {
                setSearchResult("ЭОМ не найден");
            }
        } catch (err) {
            setError('Ошибка при выполнении поиска ЭОМ');
            setSearchResult("ЭОМ не найден");
        } finally {
            setLoading(false);
        }
    };

    const openModal = async (task, isSubtask = false) => {
        setEditingTask(isSubtask ? null : task);
        setEditingSubtask(isSubtask ? task : null);
        setParentTask(null);
        setSearchResult(null);
        setLoading(true);
        setError(null);
        setShowSubtaskPanel(false);
        setIsDropdownOpen(false);

        const number = extractNumberFromTitle(task.title);
        const isMainTask = !isSubtask;

        try {
            // Получение данных таймера задачи
            const response = await axios.get(`/tasks/${task.id}`);
            const taskData = response.data;

            // Установка таймера в состояние
            setTimers((prevTimers) => ({
                ...prevTimers,
                [task.id]: taskData.timer_seconds || 0, // Таймер из БД
            }));

            if (isMainTask) {
                if (taskData.subtasks && taskData.subtasks.length > 0) {
                    setEditingTask({ ...task, subtasks: taskData.subtasks });
                }

                if (number) {
                    searchCokByNumber(number); // Поиск ЦОК
                }
            } else {
                if (task.parent_task_id) {
                    try {
                        const parentResponse = await axios.get(`/tasks/${task.parent_task_id}`);
                        setParentTask(parentResponse.data);
                    } catch (error) {
                        console.error('Ошибка при загрузке основной задачи:', error);
                    }
                }
                if (number) {
                    searchEomByNumber(number); // Поиск ЭОМ
                } else {
                    setSearchResult("номер не найден");
                    setLoading(false);
                }
            }

            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка при загрузке данных задачи:', error);
            setError('Ошибка при загрузке данных задачи');
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setEditingTask(null);
        setEditingSubtask(null);
        setIsModalOpen(false);
        setIsEditing(false);
        setSearchResult(null);
        setLoading(false);
        setError(null);
        setIsDropdownOpen(false);
        setShowSubtaskPanel(false)
    };

    const handleTaskUpdate = async () => {
        try {
            const taskToUpdate = activeTask;
            const url = `/tasks/${taskToUpdate.id}`;
            await axios.put(url, taskToUpdate);
            fetchTasks();
            setIsEditing(false);
        } catch (error) {
            console.error('Ошибка при обновлении задачи:', error);
        }
    };

    const addTask = async () => {
        try {
            await axios.post('/tasks', {
                ...newTask,
                observers: JSON.stringify(newTask.observers),
            });
            setNewTask({
                title: '',
                description: '',
                assigner_id: null,
                executor_id: null,
                observers: [],
                tag: '',
                parent_task_id: null,
                project_id: null,
                project_name: null,
            });
            fetchTasks();
            setShowTaskPanel(false);
        } catch (error) {
            console.error('Ошибка при добавлении задачи:', error);
        }
    };

    const toggleSubtasks = (task) => {
        if (selectedTaskId === task.id) {
            setSelectedTaskId(null);
            setSubtasks([]);
        } else {
            // Если задача не выбрана, загружаем подзадачи из `task.subtasks`
            if (task.subtasks && task.subtasks.length > 0) {
                setSubtasks(task.subtasks);
            } else {
                setSubtasks([]); // Если подзадач нет
            }
            setSelectedTaskId(task.id);
        }
    };

    const sortTasks = (key) => {
        // Определяем направление сортировки
        const direction = sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';

        // Обновляем состояние сортировки
        setSortConfig({ key, direction });

        // Выполняем сортировку сразу
        const sorted = [...tasks].sort((a, b) => {
            if (direction === 'ascending') {
                return a[key] > b[key] ? 1 : -1;
            } else {
                return a[key] < b[key] ? 1 : -1;
            }
        });

        // Обновляем отображаемые задачи
        setTasks(sorted);
    };

    const sortSubtasks = (key) => {
        let direction = 'ascending';
        if (subtaskSortConfig.key === key && subtaskSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSubtaskSortConfig({ key, direction });

        const sortedSubtasks = [...subtasks].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setSubtasks(sortedSubtasks);
    };

    const sortedTasks = [...tasks].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const handleParentTaskClick = async (parentTaskId) => {
        if (!parentTaskId) return;

        try {
            setEditingTask(null);
            setEditingSubtask(null);
            setParentTask(null);
            setSearchResult('Загрузка...');
            setLoading(true);
            setError(null);
            setShowSubtaskPanel(false);
            setIsDropdownOpen(false);

            const response = await axios.get(`/tasks/${parentTaskId}`);
            const mainTask = response.data;
            setEditingTask(mainTask);
            setIsModalOpen(true);

            const number = extractNumberFromTitle(mainTask.title);
            if (number) {
                searchCokByNumber(number);
            } else {
                setSearchResult("номер не найден");
            }

            setLoading(false);
        } catch (error) {
            console.error('Ошибка при загрузке родительской задачи:', error);
            setLoading(false);
            setError('Ошибка при загрузке основной задачи');
        }
    };

    // Функция для обновления статуса задачи на сервере
    const updateTaskStatus = async (task) => {
        try {
            const url = `/tasks/${task.id}`;
            await axios.put(url, task);
            setTasks((prevTasks) =>
                prevTasks.map((t) => (t.id === task.id ? { ...t, status: task.status } : t))
            );
        } catch (error) {
            console.error('Ошибка при обновлении статуса задачи:', error);
        }
    };


    // Функция для начала выполнения задачи
    const startTask = async (task) => {
        if (task) {
            try {
                task.status = 'in_progress';
                await updateTaskStatus(task); // Обновляем задачу на сервере
                startTimer(task.id); // Запускаем таймер сразу
            } catch (error) {
                console.error('Ошибка при запуске задачи:', error);
            }
        }
    };

    // Функция для завершения задачи
    const completeTask = (task) => {
        if (task) {
            task.status = 'closed';
            updateTaskStatus(task);
        }
    };

    // Функция для приостановки выполнения задачи
    const pauseTask = (task) => {
        if (task) {
            task.status = 'paused';
            updateTaskStatus(task);
        }
    };

    // Функция для отложения задачи
    const deferTask = (task) => {
        if (task) {
            task.status = 'deferred';
            updateTaskStatus(task);
        }
    };

    // Функция для возврата задачи в работу
    const returnToInProgress = (task) => {
        if (task) {
            task.status = 'in_progress';
            updateTaskStatus(task);
        }
    };

    const deleteTask = async (taskId) => {
        try {
            await axios.delete(`/tasks/${taskId}`);

            setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));

            setIsDropdownOpen(false);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Ошибка при удалении задачи:', error);
        }
    };

    const toggleImportant = async (task) => {
        try {
            const updatedImportant = task.important ? 0 : 1;
            await axios.put(`/tasks/${task.id}/important`, { important: updatedImportant });

            setTasks((prevTasks) =>
                prevTasks.map((t) =>
                    t.id === task.id ? { ...t, important: updatedImportant } : t
                )
            );

            if (editingTask && editingTask.id === task.id) {
                setEditingTask({ ...editingTask, important: updatedImportant });
            }
            if (editingSubtask && editingSubtask.id === task.id) {
                setEditingSubtask({ ...editingSubtask, important: updatedImportant });
            }
        } catch (error) {
            console.error('Ошибка при обновлении важности задачи:', error);
        }
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}ч ${minutes}м ${secs}с`;
    };

    // Запуск таймера
    const startTimer = (taskId) => {
        if (intervalRefs.current[taskId]) return; // Если таймер уже запущен

        intervalRefs.current[taskId] = setInterval(() => {
            setTimers((prevTimers) => ({
                ...prevTimers,
                [taskId]: (prevTimers[taskId] || 0) + 1,
            }));
        }, 1000);
    };

    // Остановка таймера
    const stopTimer = (taskId) => {
        clearInterval(intervalRefs.current[taskId]);
        delete intervalRefs.current[taskId];
    };

    // Сброс таймера
    const resetTimer = async (taskId) => {
        stopTimer(taskId);
        setTimers((prevTimers) => ({
            ...prevTimers,
            [taskId]: 0,
        }));

        try {
            await axios.put(`/tasks/${taskId}/timer/reset`);
        } catch (err) {
            console.error('Ошибка при сбросе таймера:', err);
        }
    };

    // Сохранение таймера в базу данных
    const saveTimer = async (taskId, timerSeconds) => {
        try {
            await axios.put(`/tasks/${taskId}/timer`, { timerSeconds });
        } catch (err) {
            console.error('Ошибка при сохранении таймера:', err);
        }
    };

    useEffect(() => {
        if (activeTask && activeTask.status === 'in_progress') {
            startTimer(activeTask.id); // Запуск таймера
        } else if (activeTask) {
            stopTimer(activeTask.id); // Остановка таймера
            saveTimer(activeTask.id, timers[activeTask.id] || 0); // Сохранение времени в БД
        }
        // Указываем все необходимые зависимости
    }, [activeTask, activeTask?.status, timers]);

    // форматируем время для предзаполненного инпута
    const prepareDatetime = (datetimeLocalValue) => {
        if (!datetimeLocalValue) return null; // Если значение пустое, возвращаем null

        const date = new Date(datetimeLocalValue);

        // Преобразуем в строку без изменения временной зоны
        const formattedDatetime = date
            .toLocaleString('sv-SE', { hour12: false })  // 'sv-SE' форматирует дату в формате 'YYYY-MM-DD HH:mm:ss'
            .replace(' ', 'T'); // Заменяем пробел на 'T' для соответствия формату базы данных

        return formattedDatetime;
    };

    return (
        <div className="workspace">
            <h2>Управление задачами</h2>
            <div className='add-task'>
                <button onClick={() => setShowTaskPanel(!showTaskPanel)}>
                    {showTaskPanel ? 'Скрыть панель задач' : 'Добавить задачу'}
                </button>
            </div>
            {showTaskPanel &&
                <CreateTaskForm
                    newTask={newTask}
                    setNewTask={setNewTask}
                    projects={projects}
                    users={users}
                    tasks={tasks}
                    addTask={addTask}
                    setShowPan={setShowTaskPanel}
                />
            }

            {/* Панель сортировки */}
            <table className='tasks-wrapper'>
                <thead>
                    <tr className="task-header">
                        <th
                            colSpan="2"
                            className={`task-sort title-sort ${sortConfig.key === 'title' ? 'active-sort' : ''}`}
                            onClick={() => sortTasks('title')}
                        >
                            Название
                            {sortConfig.key === 'title' && (
                                <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                            )}
                        </th>
                        <th
                            className={`task-sort updated-sort ${sortConfig.key === 'updated_at' ? 'active-sort' : ''}`}
                            onClick={() => sortTasks('updated_at')}
                        >
                            Активность
                            {sortConfig.key === 'updated_at' && (
                                <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                            )}
                        </th>
                        <th
                            className={`task-sort assigner-sort ${sortConfig.key === 'assigner_id' ? 'active-sort' : ''}`}
                            onClick={() => sortTasks('assigner_id')}
                        >
                            Постановщик
                            {sortConfig.key === 'assigner_id' && (
                                <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                            )}
                        </th>
                        <th
                            className={`task-sort executor-sort ${sortConfig.key === 'executor_id' ? 'active-sort' : ''}`}
                            onClick={() => sortTasks('executor_id')}
                        >
                            Исполнитель
                            {sortConfig.key === 'executor_id' && (
                                <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                            )}
                        </th>
                        <th
                            className={`task-sort tag-sort ${sortConfig.key === 'tag' ? 'active-sort' : ''}`}
                            onClick={() => sortTasks('tag')}
                        >
                            Тэг
                            {sortConfig.key === 'tag' && (
                                <span className="arrow">{sortConfig.direction === 'ascending' ? '↑' : '↓'}</span>
                            )}
                        </th>
                    </tr>
                </thead>

                <tbody className="task-list">
                    {projects.map((project) => (
                        <React.Fragment key={project.id}>
                            <tr className="project-row">
                                <td
                                    colSpan="6"
                                    className="project-td"
                                    onClick={() => setSelectedProjectId(selectedProjectId === project.id ? null : project.id)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <button
                                        className="show-tasks-button button-subtask"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Остановка всплытия события, чтобы обработчик на строке не срабатывал
                                            setSelectedProjectId(selectedProjectId === project.id ? null : project.id);
                                        }}
                                    >
                                        {selectedProjectId === project.id ? '-' : '+'}
                                    </button>

                                    {project.name}
                                </td>
                            </tr>
                            {selectedProjectId === project.id && (
                                sortedTasks
                                    .filter(task => task.project_id === project.id)
                                    .map((task) => (
                                        <React.Fragment key={task.id}>
                                            <tr className="task-wrapper">
                                                <td className="icon-container">
                                                    {task.subtasks && task.subtasks.length > 0 && (
                                                        <button className='button-subtask' onClick={() => toggleSubtasks(task)}>
                                                            {selectedTaskId === task.id ? '-' : '+'}
                                                        </button>
                                                    )}
                                                </td>
                                                <td className="task-cell title" onClick={() => openModal(task)}>
                                                    {task.title}
                                                </td>
                                                <td className="task-cell updated-at">
                                                    {task.updated_at
                                                        ? `${new Date(task.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(task.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
                                                        : 'Дата не указана'}
                                                </td>
                                                <td className="task-cell assigner">{getUserNameById(task.assigner_id, users)}</td>
                                                <td className="task-cell executor">{getUserNameById(task.executor_id, users)}</td>
                                                <td className="task-cell tag">{task.tag}</td>
                                            </tr>
                                            {selectedTaskId === task.id && (
                                                subtasks.map((subtask) => (
                                                    <tr key={subtask.id} className="subtask-row">
                                                        <td></td>
                                                        <td className="task-cell title subtask" onClick={() => openModal(subtask, true)}>
                                                            {subtask.title}
                                                        </td>
                                                        <td className="task-cell updated-at subtask">
                                                            {subtask.updated_at
                                                                ? `${new Date(subtask.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(subtask.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
                                                                : 'Дата не указана'}
                                                        </td>
                                                        <td className="task-cell assigner subtask">{getUserNameById(subtask.assigner_id, users)}</td>
                                                        <td className="task-cell executor subtask">{getUserNameById(subtask.executor_id, users)}</td>
                                                        <td className="task-cell tag subtask">{subtask.tag}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))
                            )}
                        </React.Fragment>
                    ))}

                </tbody>

            </table>

            {/* Модальное окно задачи */}
            {isModalOpen && (activeTask) && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
                        <div className='pagetitle-menu'>
                            <p class="task-detail-subtitle-status ">Задача № {(activeTask)?.id || 'ID отсутствует'} - <span>{statusMapping[(activeTask)?.status]}</span></p> {/* Перенести в левый угол */}
                            <button className='menu-button' onClick={() => setShowSubtaskPanel(!showSubtaskPanel)}>
                                {showSubtaskPanel ? 'Скрыть панель задач' : 'Добавить подзадачу'}
                            </button>
                            <span className="close-button" onClick={closeModal}>
                                &times;
                            </span>
                        </div>
                        <div className='modal-subtask'>
                            {showSubtaskPanel &&
                                <CreateTaskForm
                                    newTask={newTask}
                                    setNewTask={setNewTask}
                                    users={users}
                                    tasks={tasks}
                                    addTask={addTask}
                                    activeTask={activeTask}
                                    setShowPan={setShowSubtaskPanel}
                                    user={user}
                                />}

                        </div>
                        <div className='modal-wrapper__bottom'>
                            <div className='description'>
                                <div className='description-comment'>
                                    <div className='task-detail-header'>

                                        <div
                                            id="task-detail-important-button"
                                            className="task-info-panel-important mutable"
                                            onClick={() => toggleImportant(activeTask)}
                                        >
                                            {activeTask?.important ? 'Это важная задача 🔥' : 'Сделать важной задачей'}
                                        </div>
                                        <div className="title-edit-container">
                                            {isEditing ? (
                                                <input
                                                    style={{ width: '70%' }}
                                                    type="text"
                                                    value={activeTask?.title || ''}
                                                    onChange={(e) => {
                                                        const updatedValue = e.target.value;
                                                        if (editingTask) {
                                                            setEditingTask({ ...editingTask, title: updatedValue });
                                                        } else if (editingSubtask) {
                                                            setEditingSubtask({ ...editingSubtask, title: updatedValue });
                                                        }
                                                    }}
                                                />

                                            ) : (

                                                <h3 style={{ margin: '0' }}>
                                                    {(activeTask)?.title}
                                                </h3>

                                            )}
                                        </div>
                                    </div>
                                    <div className='description-section'>
                                        {isEditing ? (
                                            <RichTextEditor
                                                newContent={(activeTask)?.description || ''}
                                                setNewContent={(content) => {
                                                    if (editingTask) {
                                                        setEditingTask({ ...editingTask, description: content });
                                                    } else if (editingSubtask) {
                                                        setEditingSubtask({ ...editingSubtask, description: content });
                                                    }
                                                }}
                                            />
                                        ) : (
                                            activeTask?.description ? (
                                                <p dangerouslySetInnerHTML={{ __html: activeTask.description }} />
                                            ) : (
                                                <p>Описание отсутствует</p>
                                            )
                                        )}
                                    </div>
                                    <div className='check-list-wrapper'>
                                        <p>+ добавить чек-лист</p>
                                    </div>
                                    <div className='task-detail-extra'>
                                        <div className='task-detail-group'>
                                            <p>Задача в проекте:
                                                <span>
                                                    {projects?.find(project => project.id === activeTask?.project_id)?.name || "Проект не найден"}
                                                </span>
                                            </p>
                                        </div>
                                        {parentTask && editingSubtask && (
                                            <div className='task-detail-maintask'>
                                                Основная задача:
                                                <button
                                                    onClick={() => handleParentTaskClick(parentTask.id)}
                                                    style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                    {parentTask.title}
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div className='details-timer'>
                                        <p>⏳ Таймер выполнения задачи: {formatTime(timers[activeTask.id] || 0)}</p>
                                        <button className='reset-button' onClick={() => resetTimer(activeTask.id)}>🔄</button>
                                    </div>

                                    <div className='task-view-buttonset'>
                                        {(activeTask)?.status === 'new' && (
                                            <button onClick={() => startTask(activeTask)}>Начать выполнение</button>
                                        )}
                                        {(activeTask)?.status === 'in_progress' && (
                                            <button onClick={() => pauseTask(activeTask)}>Пауза</button>
                                        )}
                                        {(activeTask)?.status === 'paused' && (
                                            <button onClick={() => startTask(activeTask)}>Продолжить</button>
                                        )}
                                        {(activeTask)?.status === 'deferred' && (
                                            <button onClick={() => returnToInProgress(activeTask)}>Вернуть в работу</button>
                                        )}
                                        {(activeTask)?.status !== 'closed' && (activeTask)?.status !== 'deferred' && (
                                            <button onClick={() => completeTask(activeTask)}>Завершить</button>
                                        )}
                                        <div className='dropdown-button'>
                                            <button className='dropdown-more' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>Еще</button>
                                            {isDropdownOpen && (
                                                <div className='dropdown-menu'>
                                                    {(activeTask)?.status === 'closed' && (
                                                        <button onClick={() => returnToInProgress(activeTask)}>Вернуть в работу</button>
                                                    )}
                                                    {(activeTask)?.status !== 'deferred' && (activeTask)?.status !== 'closed' && (
                                                        <button onClick={() => deferTask(activeTask)}>Отложить</button>
                                                    )}
                                                    <button onClick={() => deleteTask(activeTask?.id)}>Удалить</button>
                                                </div>
                                            )}
                                        </div>
                                        {!isEditing ? (
                                            <button className='btn-overlay-edit' onClick={() => setIsEditing(true)}>Редактировать</button>
                                        ) : (
                                            <>
                                                <button className='btn-overlay-save' onClick={handleTaskUpdate}>Сохранить изменения</button>
                                                <button className='btn-overlay-cancel' onClick={() => setIsEditing(false)}>Выйти из редактирования</button>
                                            </>
                                        )}
                                    </div>

                                </div>

                                {editingTask && subtasks.length > 0 && (
                                    <div className='subtask-wrapper'>
                                        <div className='subtask-wrapper-header'>
                                            <>
                                                <div className='subtask-title-list'>
                                                    <h3 style={{ margin: '0' }}>Подзадачи</h3>
                                                </div>
                                                {/* Панель сортировки подзадач */}
                                                <table style={{ width: '100%' }} className='tasks-wrapper'>
                                                    <thead className='task-header'>
                                                        <tr class="task-header">
                                                            <th className="task-sort" onClick={() => sortSubtasks('title')}>Название</th>
                                                            <th className="task-sort" onClick={() => sortSubtasks('updated_at')}>Активность</th>
                                                            <th className="task-sort" onClick={() => sortSubtasks('assigner_id')}>Постановщик</th>
                                                            <th className="task-sort" onClick={() => sortSubtasks('executor_id')}>Исполнитель</th>
                                                            <th className="task-sort" onClick={() => sortSubtasks('tag')}>Тэг</th>
                                                        </tr>
                                                    </thead>
                                                    {/* Список подзадач */}
                                                    <tbody className='subtasks'>
                                                        {editingTask.subtasks.map((subtask) => (
                                                            <tr key={subtask.id} className="subtask-row">
                                                                <td className="task-cell title subtask" onClick={() => openModal(subtask, true)}>
                                                                    {subtask.title}
                                                                </td>
                                                                <td className="task-cell updated-at subtask">
                                                                    {subtask.updated_at ?
                                                                        `${new Date(subtask.updated_at).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })}, ${new Date(subtask.updated_at).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}` : 'Дата не указана'}
                                                                </td>
                                                                {/*<td className="task-cell status subtask">{subtask.status}</td>*/}
                                                                <td className="task-cell assigner subtask">{getUserNameById(subtask.assigner_id, users)}</td>
                                                                <td className="task-cell executor subtask">{getUserNameById(subtask.executor_id, users)}</td>
                                                                <td className="task-cell tag subtask">{subtask.tag}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                </table>
                                            </>
                                        </div>
                                    </div>
                                )}
                                {pinnedComment && (
                                    <div className='result-section'>
                                        <div className='result-wrapper'>
                                            <h3 style={{ margin: '0', paddingLeft: '10px', textAlign: 'left' }}>Результат</h3>
                                            <div className='comment-result'>
                                                <div className='comment-header'>
                                                    <span className={
                                                        pinnedComment.user_id === currentUserId
                                                            ? 'user-name user-current'
                                                            : 'user-name user-other'
                                                    }> {pinnedComment.user_name} </span>
                                                    <span className='className="timestamp timestamp-left"'> {new Date(pinnedComment.timestamp).toLocaleString('ru-RU', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                    })} </span>
                                                </div>
                                                <p
                                                    style={{ margin: 0, marginBottom: '20px' }}
                                                    dangerouslySetInnerHTML={{ __html: pinnedComment.comment }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="comment-section">
                                    {activeTask ? (
                                        <TaskComments
                                            taskId={activeTask.id}
                                            currentUser={currentUserId}
                                            getUserNameById={getUserNameById}
                                            users={users}
                                            setPinnedComment={setPinnedComment}
                                        />
                                    ) : (
                                        <p>Выберите задачу, чтобы просмотреть или добавить комментарии</p>
                                    )}
                                </div>

                            </div>

                            <div className='info-block'>
                                <div className='updated-task'>
                                    <p>{statusMapping[(activeTask)?.status]} <span className='time-to-status'>{new Date((activeTask)?.updated_at).toLocaleString('ru-RU', {
                                        day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                    })}</span></p>
                                </div>
                                <div className='info-block__bottom'>

                                    <div className='status-block'>
                                        <div className='status-search'>
                                            <label>{activeTask ? 'Статус ЦОК:' : 'Статус ЭОМ:'}</label>
                                            {loading ? (
                                                <p>Загрузка...</p>
                                            ) : error ? (
                                                <p>{error}</p>
                                            ) : (
                                                <p>{searchResult || "номер не найден"}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className='data-card'>
                                        <div className='timing'>
                                            <div className='created_task'>
                                                <p> Создана: <span>{new Date((activeTask)?.created_at).toLocaleString('ru-RU', {
                                                    day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit'
                                                })}</span></p>
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='deadline-task'>
                                                {!isEditing ? (
                                                    <p>Крайний срок: <span><b>{activeTask?.deadline == null ? 'Не указан' : new Date(activeTask?.deadline).toLocaleString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</b></span></p>
                                                ) : (
                                                    <p>
                                                        Крайний срок:
                                                        <span>
                                                            <input
                                                                type='datetime-local'
                                                                value={prepareDatetime(activeTask?.deadline) || ''}
                                                                onChange={(e) => {
                                                                    const value = e.target.value || null;
                                                                    if (editingTask) {
                                                                        setEditingTask({ ...editingTask, deadline: value })
                                                                    } else if (editingSubtask) {
                                                                        setEditingSubtask({ ...editingTask, deadline: value })
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                            <hr className='timing__hr' />
                                            <div className='details-timer-save'>
                                                <p> Затрачено: <span>{formatTime(timers[activeTask.id] || 0)}</span> </p>
                                            </div>
                                            <hr className='timing__hr' />
                                        </div>
                                        <div className='members'>
                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'assigner-block'}>
                                                <label>Постановщик</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.assigner_id, users)}</p>
                                                ) : (
                                                    <select
                                                        value={(activeTask)?.assigner_id || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = parseInt(e.target.value);
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, assigner_id: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, assigner_id: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    >
                                                        <option value="">Выберите постановщика</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                        disabled={!isEditing}
                                                    </select>
                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'executor-block'}>
                                                <label>Исполнитель</label>
                                                {!isEditing ? (
                                                    <p>{getUserNameById((activeTask)?.executor_id, users)}</p>
                                                ) : (
                                                    <select
                                                        value={(activeTask)?.executor_id || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = parseInt(e.target.value);
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, executor_id: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, executor_id: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    >
                                                        <option value="">Выберите исполнителя</option>
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                        disabled={!isEditing}
                                                    </select>
                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'observers-block'}>
                                                <label> Наблюдатели</label>
                                                {!isEditing ? (
                                                    <p>
                                                        {Array.isArray(activeTask?.observers) && activeTask?.observers.length > 0 ? (
                                                            activeTask.observers
                                                                .map((id) => {
                                                                    const user = users.find((user) => user.id === id);
                                                                    return user ? <p key={id}>{user.name}</p> : null;
                                                                })
                                                        ) : (
                                                            <p>Наблюдатели не назначены</p>
                                                        )}
                                                    </p>
                                                ) : (
                                                    <select
                                                        multiple
                                                        value={Array.isArray((activeTask)?.observers)
                                                            ? (activeTask)?.observers
                                                            : []}
                                                        onChange={(e) => {
                                                            const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value));
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, observers: selectedOptions });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, observers: selectedOptions });
                                                            }
                                                        }}
                                                    >
                                                        {users.map((user) => (
                                                            <option key={user.id} value={user.id}>{user.name}</option>
                                                        ))}
                                                    </select>

                                                )}
                                            </div>

                                            <div style={!isEditing ? { textAlign: 'left' } : {}} className={!isEditing ? '' : 'tags-block'}>
                                                <label>Теги</label>
                                                {!isEditing ? (
                                                    <p>{(activeTask)?.tag || 'Тег не указан'}</p>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        value={(activeTask)?.tag || ''}
                                                        onChange={(e) => {
                                                            const updatedValue = e.target.value;
                                                            if (editingTask) {
                                                                setEditingTask({ ...editingTask, tag: updatedValue });
                                                            } else if (editingSubtask) {
                                                                setEditingSubtask({ ...editingSubtask, tag: updatedValue });
                                                            }
                                                        }}
                                                        disabled={!isEditing}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
}

export default Workspace;
