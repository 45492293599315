import '../styles/emojiPool.css';

const emojiPool = [
    '😊', '😂', '😍', '😭', '🥰', '😎', '🤔', '😢', '🤩', '😡',
    '👍', '👎', '🙌', '👏', '🎉', '💯', '❤️', '🔥', '✨', '🎶',
];

export const addEmojiButton = (quillRef) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) return;

    const editorContainer = editor.container;
    const toolbar = editorContainer.previousSibling;
    let emojiList = null;

    const closeEmojiDropdown = () => {
        if (emojiList) {
            emojiList.remove();
            emojiList = null;
            document.removeEventListener('click', handleClickOutside);
        }
    };

    const handleClickOutside = (e) => {
        if (emojiList && !emojiList.contains(e.target)) {
            closeEmojiDropdown();
        }
    };

    if (!toolbar.querySelector('.ql-emoji')) {
        const button = document.createElement('button');
        button.innerHTML = '😊';
        button.type = 'button';
        button.className = 'ql-emoji';

        button.onclick = (e) => {
            e.stopPropagation();

            if (emojiList) {
                closeEmojiDropdown();
                return;
            }

            emojiList = createEmojiDropdown(quillRef, button);
            toolbar.appendChild(emojiList);

            document.addEventListener('click', handleClickOutside);
        };

        toolbar.appendChild(button);
    }
};

const createEmojiDropdown = (quillRef, button) => {
    const emojiListElement = document.createElement('div');
    emojiListElement.className = 'emoji-dropdown';

    // const buttonRect = button.getBoundingClientRect();
    // emojiListElement.style.position = 'absolute';
    // emojiListElement.style.top = `${buttonRect.top + window.scrollY - 150}px`;
    // emojiListElement.style.left = `${buttonRect.left + window.scrollX}px`;

    emojiPool.forEach((emoji) => {
        const emojiButton = document.createElement('button');
        emojiButton.type = 'button';
        emojiButton.innerHTML = emoji;
        emojiButton.onclick = (e) => {
            e.stopPropagation();
            const editor = quillRef.current?.getEditor();
            if (editor) {
                const selection = editor.getSelection();
                if (selection && selection.index != null) {
                    editor.insertText(selection.index, emoji);
                } else {
                    editor.insertText(0, emoji);
                }
            }
        };

        emojiListElement.appendChild(emojiButton);
    });

    return emojiListElement;
};

export default emojiPool;
