import React, { useState, useEffect } from 'react';
import axios from 'axios';
import RichTextEditor from './RichTextEditor';
import '../styles/taskComments.css';

const TaskComments = ({ taskId, currentUser, getUserNameById, users, setPinnedComment }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyTo, setReplyTo] = useState(null);
    const [isRichEditorActive, setIsRichEditorActive] = useState(false);
    const [isPinned, setIsPinned] = useState(false);


    // Загрузка комментариев
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await axios.get(`/tasks/${taskId}/comments`);
                setComments(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке комментариев:', error);
            }
        };

        fetchComments();
    }, [taskId]);

    // Добавление комментария
    const addComment = async () => {
        const trimmedComment = newComment.trim();
        if (!trimmedComment) return;

        try {
            const response = await axios.post(`/tasks/${taskId}/comments`, {
                userId: currentUser,
                comment: trimmedComment,
                parentCommentId: replyTo || null,
            });

            const newCommentWithUserName = {
                ...response.data,
                userId: currentUser,
                user_name: getUserNameById(currentUser, users), // Добавляем имя пользователя
            };

            setComments(prevComments => [...prevComments, newCommentWithUserName]);

            if (isPinned) {
                setPinnedComment(newCommentWithUserName); // Передаем закрепленный текст родителю
                setIsPinned(false); // Сбрасываем состояние чекбокса
            }

            setNewComment('');
            setReplyTo(null);
            setIsRichEditorActive(false);
        } catch (error) {
            console.error('Ошибка при добавлении комментария:', error);
        }
    };

    // Удаление комментария
    const deleteComment = async (commentId) => {
        try {
            await axios.delete(`/tasks/comments/${commentId}`);
            setComments(comments.filter((c) => c.id !== commentId));
        } catch (error) {
            console.error('Ошибка при удалении комментария:', error);
        }
    };

    return (
        <div className="task-comments comments-section">
            <h3 style={{ margin: '0', marginBottom: '20px' }}>Комментарии</h3>
            {comments.map((comment) => (
                <div
                    className={comment.parent_comment_id ? 'comment-wrapper' : 'comment'}
                    style={{ marginLeft: comment.parent_comment_id ? '20px' : '0px' }}
                    key={comment.id}
                >
                    <div className='comment-main'>
                        <div className="comment-header">
                            <span
                                className={
                                    comment.user_id === currentUser
                                        ? 'user-name user-current'
                                        : 'user-name user-other'
                                }
                            >
                                {comment.user_name}
                            </span>
                            <span className="timestamp timestamp-left">
                                {new Date(comment.timestamp).toLocaleString('ru-RU', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}
                            </span>
                        </div>
                        <p
                            style={{ margin: 0, marginBottom: '20px' }}
                            dangerouslySetInnerHTML={{ __html: comment.comment }}
                        />
                    </div>
                    <div className='action-buttons'>
                        <button className="reply-button" onClick={() => setReplyTo(comment.id)}>
                            Ответить
                        </button>
                        {comment.user_id === currentUser && (
                            <button
                                className="delete-button"
                                onClick={() => deleteComment(comment.id)}
                            >
                                Удалить
                            </button>
                        )}
                    </div>
                </div>
            ))}
            <div className="new-comment-section">
                <div
                    className={`input-container ${isRichEditorActive ? 'expanded' : ''}`}
                >
                    {isRichEditorActive ? (
                        <RichTextEditor
                            newContent={newComment}
                            setNewContent={setNewComment}
                        />
                    ) : (
                        <input
                            type="text"
                            className="comment-input"
                            placeholder={replyTo ? 'Ответить на комментарий' : 'Добавить комментарий'}
                            onFocus={() => setIsRichEditorActive(true)}
                        />
                    )}
                </div>

                {isRichEditorActive && (
                    <div className="comment-buttons">
                        <button className="comment-button" onClick={addComment}>
                            Отправить
                        </button>
                        <button
                            className="comment-button"
                            onClick={() => {
                                setIsRichEditorActive(false);
                                setNewComment('');
                            }}
                        >
                            Отмена
                        </button>
                        <label className='pinned-label'>
                            <input
                                className='pinned-input'
                                type='checkbox'
                                checked={isPinned}
                                onChange={e => { setIsPinned(e.target.checked) }}
                            />
                            Зафиксировать как результат работы
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TaskComments;
